
.historic-meta {

    margin-left: 5rem !important;
    margin-right: 1.55rem !important;

    .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ccc;
    }

    .col + .col {
        border-left: 1px dotted #ddd;
    }


}

