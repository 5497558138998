

/*

*/
.booking-event {

    border-radius: 4px;

    .form-divider {
        border-color: #DEE2E6;
    }

    .event-options {
        height: auto;
        display: block;

        border-top: 1px solid #DEE2E6;

        // overflow: hidden;
        // transition: 0.5s height ease-in-out;
    }

    .booking-event__add-pitch {
        display: block;
    }    

}

.booking-event.booking-event--collapsed {

    .event-options {
        display: none;
        // overflow: hidden;
        // transition: 0.5s height ease-in-out;
    }    
    
    .booking-event__add-pitch {
        display: none;
    }
}



.booking-pitch-list {
    
}

.booking-pitch {

    & + & {
        border-top: 10px solid rgb(255, 255, 255);
    }

}



.form-select[multiple], .form-select[size]:not([size="1"]){
    min-height: 175px;
}






.booking-widget {

}

.booking-widget-line {

}

.booking-widget-line + .booking-widget-line {
    margin-top: 0.5em;
    border-top: 1px solid #DEE2E6;
    padding-top: 0.5em;
}

.booking-totals__total {
    position: relative;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}







.booking-summary__row--line {

}

.booking-summary__row--line th,
.booking-summary__row--line td {
    border: none;
    padding-top: 1em;
    padding-bottom: 0.25em;
}

.booking-summary__row--pitch th,
.booking-summary__row--pitch td {
    border: none;
    padding-top: 0.15em;
    padding-bottom: 0.15em;
}

.booking-summary__row--extra th,
.booking-summary__row--extra td {
    border: none;
    padding-top: 0.15em;
    padding-bottom: 0.15em;
}

    .booking-summary__row--extra + .booking-summary__row--pitch th,
    .booking-summary__row--extra + .booking-summary__row--pitch td {
        padding-top: 1em;
    }


.booking-summary__row--total th,
.booking-summary__row--total td {
    padding-top: 1.5em;
    font-weight: 700;
}

.booking-summary__row--cancelled {
    text-decoration: line-through;
}





// .booking-summary__row--extra + .booking-summary__row--line {
//     margin-top: 0.25em;
// }

.booking-summary__row--extra + .booking-summary__row--line th,
.booking-summary__row--extra + .booking-summary__row--line td {
    padding-top: 1em;
}




.b-none {
    border-style: none;
}

.bt-none {
    border-top-style: none;
}

.bb-none {
    border-bottom-style: none;
}

.bl-none {
    border-left-style: none;
}

.br-none {
    border-right-style: none;
}





/* Special booking styles (indicators, icons etc.) */

// select dropdown option

#zoneid #react-select-2-listbox {
    width: 240px;
}

@media screen and (min-width: 996px){
    #zoneid #react-select-2-listbox {
        width: 320px;
    }
}

.zone-option {
    display: flex !important;
    justify-content: space-between;;
}

.zone-option__label {
    flex: 0 1 calc(100% - 20px);
}

.zone-option__icon {
    flex: 0 0 20px;
}

.zone--indoor {
    // background: center right url('/assets-iacf/images/icon-shop.svg') no-repeat;
}

.zone--blank {
    
}

