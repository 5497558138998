


/*
Background on logon screen
*/

body.authentication-bg {
    background: none;
}


/*
    Breadcrumb styling overrides
*/

.breadcrumb {

    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 0;

}

    .breadcrumb-item + .breadcrumb-item::before {
        font-size: inherit;
        line-height: inherit;
    }




    






/*
    Toast styling overrides
*/

.toast-container {
    z-index: 500;
}





.columns {
    columns: 1;
    column-gap: 1em;
}

.columns-2 {
    columns: 2;
    column-gap: 1em;
}
.columns-3 {
    columns: 3;
    column-gap: 1em;
}
.columns-4 {
    columns: 4;
    column-gap: 1em;
}

@media screen and (min-width: 759px){

    .columns {
        columns: 2;
    }
    
    .columns-md-2 {
        columns: 4;
    }
    .columns-md-3 {
        columns: 3;
    }
    .columns-md-4 {
        columns: 4;
    }

}

@media screen and (min-width: 992px){

    .columns {
        columns: 4;
    }
    
    .columns-lg-2 {
        columns: 4;
    }
    .columns-lg-3 {
        columns: 3;
    }
    .columns-lg-4 {
        columns: 4;
    }

}





/* Google searchbox list has a zindex of 1000, but this means 
it appears underneath Bootstrap modals */
.pac-container {
    z-index: 10000;
}








.wrapper,
.content-page {
    overflow: visible;
}








.notification-list--help {
    
    .nav-link {
        display: flex !important;
        align-items: center;        
    }

    .nav-link__label {
        
    }

}



.navbar-custom .app-search {
    overflow-y: visible;
    display: flex;
    position: relative;
}

.custom-results {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background: white;
}

a.result:focus,
a.result:hover {
    background: #eee;;
}
