
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Roboto:wght@300;400;500;700&display=swap');

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
legend,
label {
    color: #1C2B39;
}

h1, .h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    margin-bottom: 1.5rem;
}

h2, .h2 {
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    /* identical to box height */
    letter-spacing: -0.02em;

    color: #8391A2;
    margin-bottom: 1.5rem;
}

h3, .h3 {
    font-weight: 600;
    font-size: 14.3px;
    line-height: 17px;
    letter-spacing: -0.02em;
}


@media all and (min-width:992px){

    h1, .h1 {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.02em;
        margin-bottom: 1.5rem;
    }

    h2, .h2 {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.02em;
        margin-bottom: 1.5rem;
    }    

    h3, .h3 {
        font-size: 14.3px;
        line-height: 17px;
        letter-spacing: -0.02em;
    }    

}




/*
    Generic base format
*/

.white-space-normal {
    white-space: normal;
}


.enlarged-icon {
    font-size: 19px;
    line-height: 19px;
}


.normal {
    font-weight: 400;
}

.medium {
    font-weight: 500;
}

.strong {
    font-weight: 700;
}



.list-style-none {
    list-style: none;
}




.disabled-link {
    opacity: 0.35;
}

