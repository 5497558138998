.printable-ticket,
.label-list {
    color: #1C2B39;
    font-size: 10px;
    position: relative;
    
    
    // For testing the positioning of the Content
    background: rgba(255, 255, 255, 0.5);
    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    .label {
        font-weight: bold;
    }
}

.printable-ticket {
    /* Important for consistent printing */
    clear: both;
    page-break-after: always;
    /* Important for consistent printing */

    border: 1px dashed #000;

    .label {
        &--fairname {
            font-size: 2.5rem !important;
        }
    }

    // For testing the positioning of the Content
    // &::before {
    //     background-image: url('../../../images/print-reference.jpg');
    //     opacity: 0.2;
    // }

    .vehicle-pass {
        height: 388px;
    }

    .ticket-status {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .ticket-address {
        height: 240px;
        padding: 20px 105px 50px;
    }

    .extras {
        font-size: 10px;

        span {
            margin-right: 5px;
        }
    }
}

.label-list {
    border: 1px solid black;
    display: grid;
    // grid-template-columns: auto auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;

    // For testing the positioning of the Content
    // &::before {
    //     background-image: url('../../../images/print-labels-grid.jpg');
    //     opacity: 1;
    //     left: -9px;
    //     top: -2.1px;
    //     background-size: 790px;
    // }

    .label {
        display: block;
    }

    .printable-label {
        height: 144px;
        height: 143.5px;
        // border: 1px dashed #000;
        display: inline-block;
        overflow: hidden;

        /* Important for consistent printing */
        &:nth-child(21n + 1),
        &:nth-child(21n + 2),
        &:nth-child(21n + 3)  {
            margin-top: 54px;
        }

        &:nth-child(21n + 21) {
            page-break-after: always;
        }

        &:nth-child(21n + 22) {
            clear: both;
        }

        &__wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 10px;
        }
        /* Important for consistent printing */
    }

    .extra-info {
        background-color: var(--color-iacf);
        color: #fff;
    }

}


.printable-ticket.print-draft::after {

    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    // height: 90%;
    content: 'PROOF';
    opacity: 0.4;
    font-size: 200px;
    font-weight: 700;
    transform: translateY(300px) rotateZ(50deg);

}



@media print {
    .hide-print {
        display: none;
    }

    .printable-ticket,
    .label-list .printable-label,
    .label-list {
        border: 0px solid #fff;
    }
}