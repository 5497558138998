

.logo,
.logo__link,
.logo__image {
    display: block;
    margin: 0;
    padding: 0;
}

.logo {
    position: static;
    padding: 0 30px;
}

.logo__link {
        
}

.logo__image {
    width: 100%;
    max-width: 200px;
    height: 50px;
}

.leftside-menu .logo__image {
    width: auto;
    max-width: 200px;
    height: 35px;
}

// FIXME: Issue where the logo is hidden instantly, but there's a JS delay
body[data-leftbar-compact-mode=condensed] .logo {
    visibility: hidden;
}