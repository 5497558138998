
.timeline-alt .timeline-item .timeline-icon {
    border: 4px solid white;
    border-radius: 50%;
}





.timeline-item-label-warpper {

    padding-bottom: 1.5rem;

    p {
        display: inline;
        margin-right: 5px;
    }

}
