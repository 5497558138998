
.file-preview {

}

.file-preview__thumbnail { 
    background: #eee;
    aspect-ratio: 1/1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .file-preview__thumbnail--empty { 
        background: white;
    }


.file-preview__image {
    max-width: 100%;
}

.file-preview__image--preview {
    width: 51px;
    height: 51px;
}
