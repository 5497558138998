
.btn-wide {
    padding-left: 3em;
    padding-right: 3em;
}

.btn-ultrawide {
    padding-left: 5em;
    padding-right: 5em;
}


label {
    font-weight: 600;
    font-size: 14.3px;
    line-height: 17px;
    letter-spacing: -0.02em;
}

/* labels within a check/radio list */
.form-check .form-check-label {
    font-weight: 400;
}




.form-divider {
    border-bottom: 1px solid #EEF2F7;
}

    .form-divider--faint { 
        border-bottom: 1px solid #EEF2F7;
        border-color: rgba( 0, 0, 0, 0.05 );
    }








.custom-checkbox-columns {
    column-count: 1;
    list-style: none;
    padding-left: 0;
}

@media screen and (min-width: 992px){

    .custom-checkbox-columns {
        column-count: 2;
        column-gap: 2em;
    }

}








.global-filter {

    &__search-box {
        border-left: none;
    }    
    
    &__search-icon {
        background: transparent;
        border: 1px solid var(--ct-input-border-color);
        border-right: none;
        padding-right: 0;
    }

}


.table > :not(caption) > * > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem

}





button > i {
    pointer-events: none;
}





// input:invalid,
// select:invalid {
//     color: var(--ct-danger);
//     border-color: var(--ct-danger);
// }





.pitch-location {

    background-color: var(--color-primary);

    &__prefix {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }
    
    &__number {
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      letter-spacing: -0.02em;
    }
    
}




.extra {

}

.extra__label {
    display: block;
}

.extra__delete-link * {
    pointer-events: none;
}


@media screen and (min-width: 992px){

    .extra:nth-child(n+3) .extra__label {
        display: none;
        outline: 2px dotted red;
    }

    
    // .extra:nth-child(1) .extra__label {
    //     display: block
    // }

}

