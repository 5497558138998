

:root {
    
    --color-primary: #5C60F5;

    --color-iacf: rgb(191, 47, 43);
    
    --ct-primary-rgb: 92, 96, 245;

    --ct-link-color: var(--color-primary);
    --ct-link-hover-color: #4c529e;

    --ct-bg-leftbar: #1C2B39; 

}



// .todo {
//     outline: 2px dotted #c00;
// }

.sticky {
    position: sticky;
    top: 0;
}

.help-widget.sticky {
    top: 92px;
}




.position-relative {
    position: relative;
}

    .custom-loader {
        position: relative;
        min-height: 5em;
    }



.btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    text-align: center
}


.tac {
    text-align: center;
}

.tar {
    text-align: right;
}

.tal {
    text-align: left;
}









/*
    Generic base format
*/

.color-grey {
    color: var(--ct-gray-600);
    color: #8391A2;
}

.bg-form-block {
    background-color: var(--ct-gray-100);
}




/*
    Utilities
*/

.todo {
    // outline: 2px dotted #f00 !important;
    opacity: 0.5;
    pointer-events: none;
}


.subtle {
    opacity: 0.65;
}


.white-space-normal {
    white-space: normal;
}

.white-space-wrap {
    white-space: wrap;
}




.badge--status {
    padding-right: 0.65em;
}

    .badge--status i {
        margin-right: 0.25em
    }






.no-event {
    pointer-events: none;
}





.inline-list {
    padding-left: 0;
}

.inline-list > * {
    display: inline-block;
}

.inline-list > * + * {
    margin-left: 1em;
}

ul.inline-list > li::before {
    content: ' • ';
    display: inline-block;
    margin-right: 0.35em;
}

